import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 140px;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
    }

    > p {
        max-width: 1015px;
    }

    h3 {
        width: 100%;
    }
`;

const PostingsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &#less-than-two{
        justify-content: flex-start;
    }
`;

const MoreInfo = styled(Link)`
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #23C0F5;
    align-self: flex-end;
    width: 100%;
`

const PostingsContentDiv = styled.div`
    width: 355px;
    box-sizing: border-box;
    padding: 0 25px;
    margin-bottom: 56px;
    text-align: center;

    &:first-of-type, &:nth-of-type(n + 2) {
        padding-left: 0;
    }

    &:nth-of-type(n + 3) {
        padding-right: 0;
    }


    @media only screen and (max-width: 1140px) {
        width: 48%;
        padding: 0;
    }

    @media only screen and (max-width: 840px) {
        width: 100%;
        margin-bottom: 40px;
    }
`;

const PostContentDiv = styled.div`
    padding: 30px;
    border-radius: 3px;
    box-shadow: rgba(61, 123, 178, 0.4) 0px 2px 10px 0px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const query = graphql`
    query {
        allSanityCareer(sort: {fields: [_createdAt], order: ASC}) {
            totalCount
            edges {
              node {
                id
                url_slug {
                    current
                }
                name
                excerpt
                description
              }
            }
          }
      } 
`

const Postings = ({post}) => (
        <PostingsContentDiv>
            <PostContentDiv>
                <h3 className="margin-bottom-10">{post.name}</h3>
                <p>{post.excerpt}...</p>
                <MoreInfo to={getPostUrl(post.url_slug.current)}>More Info</MoreInfo>
            </PostContentDiv>
        </PostingsContentDiv>
)

function getPostUrl(slug) {
    return `/company/careers/${slug}`;
}
function getPostings(data) {
    const PostingsArray = [];
    data.allSanityCareer.edges.forEach(item => {
        PostingsArray.push(<Postings post={item.node} key={item.node.id}></Postings>)
    });

    return PostingsArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <WrapperDiv id="listings">
                <h2>Job Opportunities</h2>
                <p className="margin-bottom-60">If you’re looking for a company that values their employees, with a “work hard, play hard” mentality, please consider applying! All positions available are remote and available at all locations</p>
                <PostingsContainer id={data.allSanityCareer.totalCount <= '2' ? 'less-than-two' : ''}>
                    {getPostings(data)}
                </PostingsContainer> 
                </WrapperDiv>
                </>
            )
        }}
    />
)
