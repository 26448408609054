import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const IncentivesContainer = styled.div`
    background-color: rgba(51,187,161,.1);
`;

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 100px;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 60px;
    }

    h2 {
        width: 100%;
        margin-top: 55px;
    }
`;

const IncentivesContentIconDiv = styled.div `
    .gatsby-image-wrapper {
        width: 100%!important;
        height: 120px!important;
    }
    img {
        position: static!important;
        width: 100px!important;
        height: auto!important;
    }

`

const IncentivesContainerDiv = styled.div`
    width: 25%;
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    margin-bottom: 56px;

    @media only screen and (max-width: 1024px) {
        width: 50%;
        margin-bottom: 40px;
    }

    @media only screen and (max-width: 720px) {
        width: 100%;
        margin-bottom: 40px;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const IncentivesContentDiv = styled.div`
    height: 100%;
`;


const query = graphql`
    query {
        allSanityIncentive(sort: {fields: [order], order: ASC}) {
            totalCount
            edges {
              node {
                id
                name
                image {
                    asset {
                        fixed {
                            ...GatsbySanityImageFixed_noBase64
                        }
                    }
                }
                description
              }
            }
          }
      }
`

const Incentives = ({incentive}) => (
    <IncentivesContainerDiv>
        <IncentivesContentDiv>
            <IncentivesContentIconDiv>
                <Img fixed={incentive.image.asset.fixed}/>
            </IncentivesContentIconDiv>
            <h3 className="text-blue margin-bottom-10">{incentive.name}</h3>
            <p>{incentive.description}</p>
        </IncentivesContentDiv>
    </IncentivesContainerDiv> 
)

function getIncentives(data) {
    const incentivesArray = [];
    data.allSanityIncentive.edges.forEach(incentive => {
        incentivesArray.push(<Incentives incentive={incentive.node} key={incentive.node.id}></Incentives>)
    });

    return incentivesArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <IncentivesContainer>
                <WrapperDiv>
                    <h2 className="text-blue text-center margin-bottom-60">Incentives to work at Ladd Partners</h2>
                    <Flex>
                            {getIncentives(data)}
                        </Flex>
                    </WrapperDiv>
                </IncentivesContainer>
                </>
            )
        }}
    />
)
